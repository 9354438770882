* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  color: #1b1b1b;
  background-color: #f3f3ff;
}

@media screen and (max-width: 690px) {
  body.draweropen {
    overflow-y: hidden;
  }
}
body {
  font-family: "Open Sans", sans-serif;
}
ul {
  list-style: none;
  padding: 0;
}
h1 {
  font-size: 64px;
  font-weight: 500;
  line-height: 96px;
}
h2 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
}
h4 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 500;
}
a {
  color: inherit;
  text-decoration: none;
}
p {
  font-size: 20px;
  line-height: 25.6px;
  font-weight: 400;
}
.main-mx-w {
  max-width: 1420px;
  margin: 0 auto;
  padding: 0 10px;
}

p.strongp {
  font-weight: 700;
  font-size: 20px;
  margin: 15px 0;
}
p.no-m {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.swiper {
  overflow: visible !important;
}

@-webkit-keyframes onPagechange {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes onPagechange {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes onPagechangeMobile {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes onPagechangeMobile {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.fadein {
  opacity: 0;
}
.onPagechange {
  -webkit-animation: onPagechange 0.5s ease forwards;
          animation: onPagechange 0.5s ease forwards;
}
@media screen and (max-width: 690px) {
  .onPagechange {
    -webkit-animation: onPagechangeMobile 1s ease forwards;
            animation: onPagechangeMobile 1s ease forwards;
  }
}
.reveal {
  position: relative;
  -webkit-transform: translateY(150px);
          transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

